function isValidEmail(email) {
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

$('#inp').on('input', function () {
  $('#inp + .label').removeClass('label-error');
});

$('#email').on('input', function () {
  $('#email + .label').removeClass('label-error');
  $('#email-error').removeClass('show-err').addClass('hide-err');
});

$('#name').on('input', function () {
  $('#name + .label').removeClass('label-error');
});

$('#role').on('input', function () {
  $('#role + .label').removeClass('label-error');
});

$('#company').on('input', function () {
  $('#company + .label').removeClass('label-error');
});

const updateButtonMsg = function (id) {
  const button = document.querySelector(id);
  button.classList.add('state-1', 'animated');

  setTimeout(() => {
    button.classList.add('state-2');
    setTimeout(() => {
      button.classList.remove('state-1', 'state-2', 'animated');
    }, 2000);
  }, 2000);
};

const finalButtonMsg = function () {
  button.classList.add('state-2');
  setTimeout(setInitialButtonState, 2000);
};

const setInitialButtonState = function () {
  button.classList.remove('state-1', 'state-2', 'animated');
};

$(function () {
  $('#contact-form').submit(function (e) {
    e.preventDefault();
    var isError = false;
    const sourcePage = $('#contact-form').data('source');
    const name = $('#name').val();
    const email = $('#email').val();
    const role = $('#role').val();
    const company = $('#company').val();
    const general = $('#isGeneral').is(':checked');
    const riskkeyOnly = $('#isRiskkeyOnly').is(':checked');
    const services = $('#isService').is(':checked');
    const asset = $('#isAsset').is(':checked');
    const strategies = $('#isStrategies').is(':checked');
    const alSpace = $('#isAlSpace').is(':checked');
    const esg = $('#isEsg').is(':checked');
    const dynamicPacing = $('#isDynamicPacing').is(':checked');
    const message = $('#inp').val();
    const v3_site_key = '6LfsRUsaAAAAAOyeU0QHvm0dxnXr_iSmk7KtRsAy';

    if (!$.trim(name).length) {
      $('#name + .label').addClass('label-error');
      isError = true;
    } else {
      $('#name + .label').removeClass('label-error');
    }

    // if(!$.trim(message).length) {
    //   $('#inp + .label').addClass('label-error');
    //   isError = true;
    // } else {
    //   $('#inp + .label').removeClass('label-error');
    // }

    if (!$.trim(email).length) {
      $('#email + .label').addClass('label-error');
      isError = true;
    } else {
      if (!isValidEmail(email)) {
        $('#email + .label').addClass('label-error');
        $('#email-error')
          .removeClass('hide-err')
          .addClass('show-err')
          .text('Invalid email format.');
        isError = true;
      } else {
        $('#email + .label').removeClass('label-error');
        $('#email-error').removeClass('show-err').addClass('hide-err');
      }
    }

    if (!$.trim(role).length) {
      $('#role + .label').addClass('label-error');
      isError = true;
    } else {
      $('#role + .label').removeClass('label-error');
    }

    if (!$.trim(company).length) {
      $('#company + .label').addClass('label-error');
      isError = true;
    } else {
      $('#company + .label').removeClass('label-error');
    }

    if (!isError) {
      updateButtonMsg('#send-button');
      grecaptcha.ready(function () {
        grecaptcha
          .execute(v3_site_key, { action: 'homepage' })
          .then(function (token) {
            $.ajax({
              type: 'POST',
              // url: 'https://trial.aiperion.ai/contact',
              // url: 'http://localhost:7070/contact',
              // url: 'https://api-dev.aiperion.net/contact',
              url: 'https://api-riskkey.aiperion.ai/contact',
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'g-recaptcha-response': token,
              },
              data: JSON.stringify({
                sourcePage: sourcePage,
                name: name,
                email: email,
                company: company,
                role: role,
                isAsset: asset,
                isStrategies: strategies,
                isAlSpace: alSpace,
                isEsg: esg,
                isGeneral: general,
                isRiskeyOnly: riskkeyOnly,
                isService: services,
                isDynamicPacing: dynamicPacing,
                message: message,
              }),
              success: function (result) {
                $('#contact-form')[0].reset();
                // $('#contact-form-container').toggleClass("active");
                // switchContent($("#button-text")[0], 'SAY HELLO', 'CLOSE');
              },
              error: function (error) {
                console.log(error);
              },
            });
          });
      });
    }
  });
});

$('#footer-email').on('input', function () {
  $('#footer-email-error').removeClass('show-err').addClass('hide-err');
  $('#footer-email + .floating-label').removeClass('label-error');
});

$('#footer-name').on('input', function () {
  $('#footer-name + .floating-label').removeClass('label-error');
});

$('#footer-role').on('input', function () {
  $('#footer-role + .floating-label').removeClass('label-error');
});

$(function () {
  $('#footer-form').submit(function (e) {
    e.preventDefault();
    var isError = false;
    const sourcePage = $('#footer-form').data('source');
    const name = $('#footer-name').val();
    const email = $('#footer-email').val();
    const company = $('#footer-company').val();
    const role = $('#footer-role').val();
    const general = false;
    const riskkeyOnly = false;
    const services = false;
    const asset = false;
    const strategies = false;
    const alSpace = false;
    const esg = false;
    const dynamicPacing = false;
    const message = '';
    const v3_site_key = '6LfsRUsaAAAAAOyeU0QHvm0dxnXr_iSmk7KtRsAy';

    if (!$.trim(name).length) {
      $('#footer-name + .floating-label').addClass('label-error');
      isError = true;
    } else {
      $('#footer-name + .floating-label').removeClass('label-error');
    }

    if (!$.trim(role).length) {
      $('#footer-role + .floating-label').addClass('label-error');
      isError = true;
    } else {
      $('#footer-role + .floating-label').removeClass('label-error');
    }

    if (!$.trim(email).length) {
      $('#footer-email + .floating-label').addClass('label-error');
      isError = true;
    } else {
      if (!isValidEmail(email)) {
        $('#footer-email + .floating-label').addClass('label-error');
        $('#footer-email-error')
          .removeClass('hide-err')
          .addClass('show-err')
          .text('Invalid email format.');
        isError = true;
      } else {
        $('#footer-email-error').removeClass('show-err').addClass('hide-err');
        $('#footer-email + .floating-label').removeClass('label-error');
      }
    }
    if (!isError) {
      updateButtonMsg('#landing-form-button');
      grecaptcha.ready(function () {
        grecaptcha
          .execute(v3_site_key, { action: 'homepage' })
          .then(function (token) {
            $.ajax({
              type: 'POST',
              // url: 'https://trial.aiperion.ai/contact',
              // url: 'http://localhost:7070/contact',
              // url: 'https://api-dev.aiperion.net/contact',
                 url: 'https://api-riskkey.aiperion.ai/contact',
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'g-recaptcha-response': token,
              },
              data: JSON.stringify({
                sourcePage: sourcePage,
                name: name,
                email: email,
                company: company,
                role: role,
                isAsset: asset,
                isStrategies: strategies,
                isAlSpace: alSpace,
                isEsg: esg,
                isGeneral: general,
                isRiskeyOnly: riskkeyOnly,
                isService: services,
                isDynamicPacing: dynamicPacing,
                message: message,
              }),
              success: function (result) {
                $('#footer-form')[0].reset();
              },
              error: function (error) {
                console.log(error);
              },
            });
          });
      });
    }
  });
});
